<template>
  <base-page>
    <Filters v-if="filterModel" :model="filterModel"
      color="reseau" slot="left-column" />
    <router-view />
  </base-page>
</template>

<script>
import { mapGetters } from 'vuex'

import { Filters } from '../../components/filters'

export default {
  name: 'CiblePage',
  components: { Filters },

  meta () {
    return {
      titleTemplate: title => `${title} - Cibles | AGORA`
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      return store.dispatch('cibles/getCible', currentRoute.params.id)
        .then(() => {
          const droits_title = store.getters['cibles/getCurrentCible'].statut
          return store.dispatch('cibles/fetchFormFields', { droits_title })
        })
    } else {
      return store.dispatch('cibles/fetchFormFields', { droits_title: 'create' })
    }
  },

  beforeDestroy () {
    this.$store.commit('cibles/setCurrentCible', null)
  },

  computed: {
    ...mapGetters({
      cible: 'cibles/getCurrentCible'
    }),

    filterModel () {
      const routeName = this.$route.name
      if (routeName === 'cible-evenements') { return 'evenements' }
      return null
    }
  },

  watch: {
    cible: {
      handler (newVal, oldVal) {
        if (!newVal) return
        this.$store.commit('pages/setPageTitle', newVal['label'])

        const id = newVal['id']
        // const { evenements_count: evenements, notes_count: notes } = newVal

        this.$store.commit('pages/setTabs', [
          { name: 'informations', label: 'Informations', to: { name: 'cible-show', params: { id } } },
          // { name: 'events', label: `${evenements} ${this.$pluralize(this.$t('evenements.evenement'), evenements)}`, to: { name: 'cible-evenements', params: { id } } },
          { name: 'events', label: `Événements`, to: { name: 'cible-evenements', params: { id } } },
          { name: 'notes', label: 'Notes perso', to: { name: 'cible-notes', params: { id } } },
          { name: 'history', label: 'Historique', to: { name: 'cible-show', params: { id } } }
        ])
      },
      immediate: true
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('cibles.cibles_list'), to: { name: 'cibles-list' } },
      {}
    ])

    if (!this.$route.params.id) {
      this.$store.commit('pages/setPageTitle', this.$t(`cibles.add_cible`))
      this.$store.commit('pages/setTabs', [])
    }
  }
}
</script>
